.patient-dialog {
    max-width: 1100px;
    min-width: 40vw;
    width: 100%;
    height: 50%;
    min-height: 500px;
    margin: 0 !important;
    padding: 22px 11px 0;
    overflow: hidden !important;
}

.patient-dialog .selector-title {
    padding-left: 10px;
}

.patient-dialog .selector-title > span {
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
}

.patient-dialog .close-button {
    float: right;
}


.patient-dialog .patients-wrapper {
    padding: 0 20px;
    height: calc(100% - 36px);
}

.patient-dialog .patients-wrapper > div:last-of-type {
    max-height: calc(100% - 64px);
    overflow: auto;
    margin-top: 15px;
    box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
}

.patient-dialog .patients-wrapper .patients-table {
    background-color: #fff;
    box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
}