.ehr-content-wrapper {
    height: calc(100% - 82px);
    width: calc(100% - 224px);
    overflow: auto;
}

.ehr-content-wrapper.padding {
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;
    padding-top: 200px;
}

.ehr-content-wrapper.padding > * {
    display: block;
    font-size: 20px;
}