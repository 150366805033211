.header-wrapper {
    float: left;
    width: 100%;
    height: 80px;
    background-color: #478454;
}

.header-persona-info-wrapper {
    overflow: hidden;
    height: 63px;
    float: left;
    margin-top: 10px;
    min-width: 210px;
}

.header-persona-info-row {
    margin-top: 23px;
}

.header-persona-wrapper, .header-patient-wrapper {
    display: inline-block;
}

.header-patient-wrapper {
    width: 80%;
    position: relative;
    padding-left: 25px;
    box-sizing: border-box;
    vertical-align: top;
}

.header-context-wrapper {
    width: 54%;
    box-sizing: border-box;
    vertical-align: top;
    display: inline-block;
    background: #478454;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 25px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    position: absolute;
    left: 23%;
    overflow: hidden;
}

.header-context-wrapper:hover {
    cursor: pointer;
    background: #40807A;
}

.header-context-wrapper .edit-button {
    color: white;
    position: absolute;
    top: -3px;
    right: 5px;
    margin-left: 25px;
    opacity: 0;
    transition: all .3s;
}

.header-context-wrapper:hover .edit-button {
    opacity: 1;
    right: 5px;
    transition: all .3s;
}

.header-context-wrapper.hidden {
    opacity: 0;
}

.header-context-wrapper > div {
    display: inline-block;
    width: 150px;
    vertical-align: top;
}

.header-persona-wrapper {
    width: 20%;
    position: absolute;
    min-width: 240px;
}

.patient-icon-wrapper,
.persona-icon-wrapper {
    float: left;
    width: 74px;
    height: 74px;
    position: relative;
    top: 3px;
    border-radius: 40px;
    transition: all .3s;
    color: white;
}

.persona-icon-wrapper {
    margin-right: 1rem;
    top: 0;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.patient-icon-wrapper {
    margin-right: 1rem;
}

/*.header-persona-wrapper > div {*/
/*    width: 300px;*/
/*}*/

.header-persona-wrapper > div > div {
    display: inline-block;
}

.header-persona-wrapper > div > div:nth-of-type(2) {
    width: calc(100% - 100px);
}

.header-persona-wrapper > div > div:nth-of-type(2):hover,
.header-patient-wrapper > div > div:first-of-type:hover,
.header-persona-wrapper > div > div:nth-of-type(2).active,
.header-patient-wrapper > div > div:first-of-type.active {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
    transition: all .3s;
}

.header-info {
    color: white;
}

.header-info {
    width: 130px;
    display: inline-block;
    font-weight: 100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-info.dob,
.header-info.mrn {
    width: 120px !important;
}

.patient-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.header-patient-info-wrapper {
    padding-top: 10px;
    cursor: pointer;
}

.header-patient-info-row {
    width: 26%;
    min-width: 230px;
}

.header-patient-info {
    color: white;
}

.dob, .mrn, .gender, .age {
    font-size: .8rem;
    margin-right: .5rem;
}

.dob span:first-of-type,
.mrn span:first-of-type,
.gender span:first-of-type,
.age span:first-of-type {
    display: inline-block;
    height: 1.2rem;
    line-height: 1.2rem;
    font-weight: bold;
    margin-right: .3rem;
}

.persona-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.persona-id {
    font-size: .8rem;
}

.persona-id span:first-of-type {
    display: inline-block;
    width: 60px;
    height: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
}

.context-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    color: white;
    font-weight: bold;
}

.context-icon.custom {
    margin-right: .5rem;
}

.context-icon svg,
.bulb path {
    fill: white !important;
    font-weight: bold;
}

.section-value {
    display: inline-block;
    height: 24px;
    margin-right: 1rem;
    vertical-align: top;
}

.section-value > * {
    vertical-align: top;
}

.section-value span:nth-of-type(2) {
    vertical-align: top;
    line-height: 23px;
    margin-left: .3rem;
    color: white;
}

.custom-context {
    color: white;
    display: inline-block;
    height: 24px;
    vertical-align: top;
}

.custom-context span:first-of-type {
    min-width: 2rem;
    margin-right: .2rem;
}

.custom-context span:nth-of-type(2) {
    margin-right: 1rem;
}

.custom-context span {
    display: inline-block;
    vertical-align: top;
    line-height: 23px;
}

.custom-context-wrapper .close-button {
    background: transparent !important;
    box-shadow: none;
    position: absolute;
    right: 20px;
    top: 10px;
}

.custom-context-table-wrapper {
    width: 545px;
    min-height: 300px;
    padding: 10px 20px;
}

.custom-context-wrapper .section-title {
    font-size: 18px;
    padding: 20px 30px;
}

.add-custom-context {
    position: absolute !important;
    right: 20px;
}

@media screen and (max-width: 1360px) {
    .header-patient-info-wrapper > div:not(:first-of-type) {
        display: inline-block;
    }
}
